import React from "react"
import {Typography, Card, CardMedia, CardActionArea, CardActions, CardContent, Button, Grid} from '@material-ui/core'
import { makeStyles} from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    root: {
        maxWidth: 345,
      },
      media: {
        height: 240,
        width: 180
      },
      btn:{
        width:"100%",
        backgroundColor: '#ad1d21',
        borderColor: '#007bff',
        color:"#fff",
        fontFamily: 'Montserrat' 
      },
      font:{
        fontFamily:"Arial Black"
      }
}));

const MagazineTemplate= ({magazine}) =>{
    const classes = useStyles();  
    console.log(magazine)
    if(!magazine) return null
    return (
        <Card className={classes.root}>
          <CardActionArea>
            <CardContent>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center">
                <Typography gutterBottom variant="h5" component="h2" className={classes.font} >
                  {magazine.title/*/magazine.createdAt.replace(/^./, magazine.createdAt[0].toUpperCase())*/}
                </Typography>
                <CardMedia
                className={classes.media}
                image={magazine.img}
                title={magazine.title}//"Contemplative Reptile"
                />
            </Grid>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Grid
                container
                direction="column"
                justify="center"
                alignItems="center">
            <Button className={classes.btn} size="small"  variant="contained" onClick={()=>{window.open(magazine.pdf.replace('nyc3.digitaloceanspaces.com/sitimm-files', 'https://sitimm-files.nyc3.digitaloceanspaces.com'))}}>
              Ver Revista
            </Button>
            </Grid>
          </CardActions>
        </Card>
      );
}
export default MagazineTemplate